import ZoomToIcon from '@mui/icons-material/CenterFocusWeakRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import MoreIcon from '@mui/icons-material/MoreVert';
import PaletteIcon from '@mui/icons-material/PaletteRounded';
import RegenerateIcon from '@mui/icons-material/RestartAltRounded';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccountRounded';
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, alpha, useTheme } from '@mui/material';
import type React from 'react';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';

type Props = {
    onSwitchToFranchisee: () => void;
    onEditArea: () => void;
    onChangeColor: (event: MouseEvent) => void;
    onZoomTo: () => void;
    onRegenerateArea: () => void;
    onDeselect: () => void;
    switchToFranchiseeDisabled?: boolean;
};

export const FranchiseeItemActionMenu: React.FC<Props> = ({
    onSwitchToFranchisee,
    onEditArea,
    onChangeColor,
    onZoomTo,
    onRegenerateArea,
    onDeselect,
    switchToFranchiseeDisabled,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSwitchToFranchisee = () => {
        onSwitchToFranchisee();
        setAnchorEl(null);
    };

    const handleZoomTo = () => {
        onZoomTo();
        setAnchorEl(null);
    };

    const handleEditArea = () => {
        onEditArea();
        setAnchorEl(null);
    };

    const handleChangeColor = (event: MouseEvent) => {
        onChangeColor(event);
        setAnchorEl(null);
    };

    const handleRegenerateArea = () => {
        onRegenerateArea();
        setAnchorEl(null);
    };

    const handleDeselect = () => {
        onDeselect();
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton onClick={handleClick}>
                <MoreIcon />
            </IconButton>

            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={handleEditArea}>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-edit-area')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleZoomTo}>
                    <ListItemIcon>
                        <ZoomToIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-zoom-to')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleSwitchToFranchisee} disabled={switchToFranchiseeDisabled}>
                    <ListItemIcon>
                        <SwitchAccountIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-switch-to')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleChangeColor}>
                    <ListItemIcon>
                        <PaletteIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-change-color')}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={handleRegenerateArea}
                    sx={{ 'color': 'error.main', '&:hover': { backgroundColor: alpha(theme.palette.error.main, 0.04) } }}
                >
                    <ListItemIcon>
                        <RegenerateIcon color="error" />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-regenerate-area')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeselect}>
                    <ListItemIcon>
                        <CloseIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-deselect')}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};
