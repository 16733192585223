import ArrowRightIcon from '@mui/icons-material/ArrowForwardRounded';
import UltimateUpgradeIcon from '@mui/icons-material/AutoAwesomeRounded';
import ConnectedIcon from '@mui/icons-material/CheckCircleRounded';
import AttatchPaymentAccountIcon from '@mui/icons-material/LinkRounded';
import { Box, Button, Link, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { DialogRoot, GradientButton, LocalisedText } from '@nexdynamic/nex-ui-react';
import type { PaymentAccountProviders } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../../ApplicationState';
import { ConnectedServicesService } from '../../../../ConnectedServices/ConnectedServicesService';
import type { IConnectedServiceInfo } from '../../../../ConnectedServices/IConnectedServiceInfo';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import ActionableListItem from '../../../email-settings/components/ActionableListItem';
import useTranslation from '../../../hooks/useTranslation';
import { GcBankDataInstitutionsList } from '../components/GcBankDataInstitutionsList';
import { GcBankDataProvider } from '../providers/GcBankDataProvider';

export type GcBankDataConnectedDialogOptions = {
    connectedProvider: IConnectedServiceInfo;
    allConnectedProviders: Array<IConnectedServiceInfo>;
};

type Props = AureliaReactComponentDialogComponentStandardProps<string> & GcBankDataConnectedDialogOptions;

export const GcBankDataConnectedDialog: AureliaReactComponentDialogComponent<GcBankDataConnectedDialogOptions> = ({
    connectedProvider,
    allConnectedProviders,
}: Props) => {
    const { t } = useTranslation();

    const handleLinkPaymentAccounts = () => {
        ConnectedServicesService.importPaymentAccounts(connectedProvider.id as PaymentAccountProviders);
    };

    return (
        <GcBankDataProvider>
            <DialogRoot sx={{ height: '100%', pt: 3, gap: 3 }}>
                <Box>
                    <Typography variant="h4" gutterBottom>
                        {t('connected-services.connected-congratulations')}
                    </Typography>

                    <Typography sx={{ fontSize: 16 }}>
                        <LocalisedText
                            text={t('connected-services.connected-description')}
                            tokens={{ provider: connectedProvider.name }}
                            styledTokens={{ provider: { fontWeight: 'bold', color: 'primary.main' } }}
                        />
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="h6" gutterBottom>
                        {t('connected-services.banks-connected')}
                    </Typography>

                    <Stack spacing={1}>
                        {allConnectedProviders.map(provider => (
                            <ActionableListItem
                                key={provider.id}
                                primary={provider.name}
                                slots={{
                                    avatar: (
                                        <img
                                            width={40}
                                            height={40}
                                            src={provider.providerLogoUrl}
                                            alt={provider.name}
                                            style={{ borderRadius: '4px' }}
                                        />
                                    ),
                                    secondaryAction: <ConnectedIcon color="success" sx={{ width: 24, height: 24 }} />,
                                }}
                            />
                        ))}
                    </Stack>
                </Box>

                <Box mb={1}>
                    <Typography variant="h6">{t('connected-services.next-steps')}</Typography>
                    <Stepper orientation="vertical">
                        <Step active>
                            <StepLabel>{t('connected-services.step-one-title')}</StepLabel>
                            <StepContent>
                                <Stack spacing={1} alignItems="flex-start">
                                    <Typography>
                                        <LocalisedText
                                            text={t('connected-services.step-one-description')}
                                            tokens={{ provider: connectedProvider.name }}
                                            styledTokens={{ provider: { fontWeight: 'bold', color: 'primary.main' } }}
                                        />
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={<AttatchPaymentAccountIcon />}
                                        onClick={handleLinkPaymentAccounts}
                                    >
                                        {t('connected-services.link-payment-account')}
                                    </Button>
                                </Stack>
                            </StepContent>
                        </Step>
                        <Step active>
                            <StepLabel>{t('connected-services.step-two-title')}</StepLabel>
                            <StepContent>
                                <Stack spacing={1} alignItems="flex-start">
                                    <Typography>{t('connected-services.step-two-description')}</Typography>

                                    <Link href="/chart-of-accounts">
                                        <Button variant="contained" color="primary" endIcon={<ArrowRightIcon />}>
                                            {t('connected-services.go-to-chart-of-accounts')}
                                        </Button>
                                    </Link>
                                </Stack>
                            </StepContent>
                        </Step>

                        {!ApplicationState.hasUltimateOrAbove && (
                            <Step active>
                                <StepLabel>{t('connected-services.step-three-title')}</StepLabel>
                                <StepContent>
                                    <Stack spacing={1} alignItems="flex-start">
                                        <Typography>{t('connected-services.step-three-description')}</Typography>

                                        <GradientButton variant="contained" color="secondary" endIcon={<UltimateUpgradeIcon />}>
                                            {t('connected-services.step-three-title')}
                                        </GradientButton>
                                    </Stack>
                                </StepContent>
                            </Step>
                        )}
                    </Stepper>
                </Box>

                {ApplicationState.hasUltimateOrAbove && (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {t('connected-services.connect-another-bank-account')}
                        </Typography>
                        <GcBankDataInstitutionsList limit={10} />
                    </Box>
                )}
            </DialogRoot>
        </GcBankDataProvider>
    );
};
