import { Typography } from '@mui/material';
import { DialogRoot, LocalisedText } from '@nexdynamic/nex-ui-react';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import useTranslation from '../../../hooks/useTranslation';
import { GcBankDataInstitutionsList } from '../components/GcBankDataInstitutionsList';
import { GcBankDataProvider } from '../providers/GcBankDataProvider';

const GcBankDataDialog: AureliaReactComponentDialogComponent = () => {
    const { t } = useTranslation();

    return (
        <GcBankDataProvider>
            <DialogRoot sx={{ mt: 1 }}>
                <Typography variant="h4">{t('connected-services.gc-bank-data-select-bank-title')}</Typography>
                <Typography variant="body1" fontSize={16} mb={1}>
                    <LocalisedText
                        text={t('connected-services.gc-bank-data-select-bank-description')}
                        tokens={{ finDataPartner: 'GoCardless' }}
                        styledTokens={{ finDataPartner: { fontWeight: 'bold', color: 'primary.main' } }}
                    />
                </Typography>

                <GcBankDataInstitutionsList />
            </DialogRoot>
        </GcBankDataProvider>
    );
};

export default GcBankDataDialog;
