import type { Institution } from '@nexdynamic/squeegee-common';

const ranking: Record<string, number> = {
    'monzo': 127,
    'starling': 123,
    'santander': 65,
    'natwest': 59,
    'barclays': 41,
    'barclays-business': 32,
    'lloyds': 31,
    'halifax': 27,
    'lloyds-business': 26,
    'tide': 19,
    'mettle': 16,
    'revolut': 16,
    'hsbc-business': 15,
    'tsb': 15,
    'nationwide': 12,
    'bos-business': 11,
    'hsbc': 10,
    'rbs': 7,
    'cashplus': 4,
    'aib': 3,
    'bos': 3,
    'revolut-ie': 3,
    'first-direct': 2,
    'transferwise': 2,
    'ulster': 2,
    'oauth-starling': 1,
    'amex': 1,
    'ptsb': 1,
    'rbs-business': 1,
};

const predeterminedMapping: Record<string, string> = {
    AIB_AIBKGB2LXXX: 'aib',
    AIB_FTBKGB2BXXX: 'aib',
    AIB_CORP_AIBKGB2LXXX: 'aib',
    BANK_OF_SCOTLAND_BUSINESS_BOFSGBS1: 'bos-business',
    BANK_OF_SCOTLAND_COMMERCIAL_BOFSGBS1: 'bos-business',
    BANK_OF_SCOTLAND_BOFSGBS1: 'bos',
    BARCLAYCARD_COMMERCIAL_BUKBGB22: 'barclays',
    BARCLAYCARD_BUKBGB22: 'barclays',
    BARCLAYS_BUSINESS_BUKBGB22: 'barclays',
    BARCLAYS_CORPORATE_BUKBGB22: 'barclays',
    BARCLAYS_BUKBGB22: 'barclays',
    BARCLAYS_WEALTH_BUKBGB22: 'barclays',
    FIRST_DIRECT_MIDLGB22: 'first-direct',
    HALIFAX_HLFXGB22: 'halifax',
    HSBC_BUSINESS_HBUKGB4B: 'hsbc-business',
    HSBC_KINETIC_HBUKGB4B: 'hsbc-business',
    HSBC_HBUKGB4B: 'hsbc',
    HSBC_NET_HBUKGB4B: 'hsbc',
    LLOYDS_BUSINESS_LOYDGB2L: 'lloyds',
    LLOYDS_COMMERCIAL_LOYDGB2L: 'lloyds',
    LLOYDS_LOYDGB2L: 'lloyds',
    METTLE_NWBKGB2L: 'mettle',
    MONZO_MONZGB2L: 'monzo',
    NATIONWIDE_NAIAGB21: 'nationwide',
    NATWEST_NWBKGB2L: 'natwest',
    NATWEST_CORP_NWBKGB2L: 'natwest',
    NATWEST_CS_NWBKGB2L: 'natwest',
    NATWEST_INTERNATONAL_RBOSGIGI: 'natwest',
    REVOLUT_REVOGB21: 'revolut',
    RBS_GB_RBSSGBKC: 'rbs',
    RBS_GB_CORP_RBSSGBKC: 'rbs',
    RBS_GB_CS_RBSSGBKC: 'rbs',
    SANTANDER_GB_ABBYGB2L: 'santander',
    STARLING_SRLGGB3L: 'starling',
    TIDE_TIDEGB00X01: 'tide',
    TSB_GB_TSBSGB2A: 'tsb',
    ULSTER_ULSBGB2B: 'ulster',
    WISE_TRWIGB22: 'transferwise',
    CASHPLUS_NWBKGB2L: 'cashplus',
};

const getRanking = (institutionId: string) => {
    if (ranking[institutionId]) {
        return ranking[institutionId];
    }

    if (predeterminedMapping[institutionId]) {
        return ranking[predeterminedMapping[institutionId]];
    }

    return 0;
};

export const sortInstitutionsByRanking = (institutions: Array<Institution>) => {
    return institutions.sort((a, b) => getRanking(b.id) - getRanking(a.id));
};
