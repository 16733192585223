import type { Institution, InstitutionsResponse } from '@nexdynamic/squeegee-common';
import { extractGcBankDataError } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { createContext, useContext, useEffect, useState } from 'react';
import { Logger } from '../../../../Logger';
import { Api } from '../../../../Server/Api';
import { sortInstitutionsByRanking } from '../institutionRanking';

type GcBankDataState = {
    loading: boolean;
    institutions: Array<Institution>;
};

export const GcBankDataContext = createContext<GcBankDataState | undefined>(undefined);

export const GcBankDataProvider = ({ children }: { children: React.ReactNode }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(true);
    const [institutions, setInstitutions] = useState<Array<Institution>>([]);

    useEffect(() => {
        setLoading(true);
        const fetchInstitutions = async () => {
            try {
                // make API reqwuest to fetch institutions
                const response = await Api.get<InstitutionsResponse>(null, '/api/banking-data/institutions');
                const { data, status, statusText } = response ?? {};

                if (status !== 200) {
                    const { summary, detail } = extractGcBankDataError(data, statusText);
                    enqueueSnackbar(summary, { variant: 'error' });
                    Logger.error(summary, detail);
                }

                // If data is an array, we have our institutions
                if (Array.isArray(data)) {
                    // sort by ranking
                    const sortedInstitutions = sortInstitutionsByRanking(data);
                    setInstitutions(sortedInstitutions);
                } else {
                    // Something went seriously wrong
                    const unexpectedError = 'Unexpected error: institutions data is not an array';
                    enqueueSnackbar(unexpectedError, { variant: 'error' });
                    Logger.error(unexpectedError, data);
                }
            } catch (error) {
                let errorMessage = 'An unknown error occurred';
                if (error instanceof Error) errorMessage = error.message;
                enqueueSnackbar(errorMessage, { variant: 'error' });
                Logger.error(errorMessage);
            } finally {
                setLoading(false);
            }
        };

        fetchInstitutions();
    }, []);

    return (
        <GcBankDataContext.Provider
            value={{
                loading,
                institutions,
            }}
        >
            {children}
        </GcBankDataContext.Provider>
    );
};

export const useGcBankData = () => {
    const context = useContext(GcBankDataContext);
    if (context === undefined) {
        throw new Error('useGcBankData must be used within a GcBankDataProvider');
    }
    return context;
};
