import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import SandboxIcon from '@mui/icons-material/Widgets';
import { Divider } from '@mui/material';
import { ShineBorder } from '@nexdynamic/nex-ui-react';
import ActionableListItem from '../../../email-settings/components/ActionableListItem';
import useStateFlags from '../../../hooks/useStateFlags';

type Props = {
    onSelect: (institutionId: string) => void;
};

export const SandboxInstitution: React.FC<Props> = ({ onSelect }) => {
    const { devMode } = useStateFlags();

    const handleSelect = () => {
        onSelect('SANDBOXFINANCE_SFIN0000');
    };

    if (!devMode) return null;

    return (
        <>
            <ShineBorder sx={{ width: '100%' }}>
                <ActionableListItem
                    primary="Sandbox Institution"
                    onClick={handleSelect}
                    slots={{
                        secondaryAction: <ChevronRightIcon />,
                        icon: <SandboxIcon />,
                    }}
                    slotProps={{ avatarProps: { color: 'primary' }, paper: { elevation: 2 } }}
                />
            </ShineBorder>
            <Divider sx={{ my: 1 }} />
        </>
    );
};
